import { useMediaQuery, useTheme } from '@mui/material';
import React from 'react';
import ReviewCard from './ReviewCard';

const ReviewCardMain = () => {
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <React.Fragment>
            <div style={{margin: "3rem"}}>
                <div style={{display: "flex"}}>
                    <ReviewCard />
                    <ReviewCard />
                    <ReviewCard />
                </div>
            </div>

        </React.Fragment>
    );
}

export default ReviewCardMain;
